import { useEffect, useState } from 'react';
import CalendarDays from './CalendarDays/CalendarDays';
import analyticsService from '../../analytics/analytics-service';

export const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const FULL_MONTHS = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
export const FULL_WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
export default function Calendar(props) {

  const [month, updateMonth] = useState(props.startDay.getMonth());
  const [year, updateYear] = useState(props.startDay.getFullYear());
  const [backDisabled, updateBackDisabled] = useState(false);
  const [forwardDisabled, updateForwardDisabled] = useState(false);
  const [startDay, updateStartDay] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [endDay, updateEndDay] = useState(new Date(new Date().setHours(0, 0, 0, 0)));

  useEffect(() => {
    let backArrowDisabled = true;
    let forwardArrowDisabled = true;
    const startYear = startDay.getFullYear();
    const endYear = endDay.getFullYear();
    if ((startYear === year && month > startDay.getMonth()) || (startYear < year)) {
      backArrowDisabled = false;
    }
    if ((endYear === year && month < endDay.getMonth()) || (endYear > year)) {
      forwardArrowDisabled = false;
    }
    updateBackDisabled(backArrowDisabled);
    updateForwardDisabled(forwardArrowDisabled);
  }, [month, year, startDay, endDay])

  useEffect(() => {
    updateStartDay(props.startDay);
    updateEndDay(props.endDay);
    updateMonth(props.startDay.getMonth());
    updateYear(props.startDay.getFullYear());
  }, [props.startDay, props.endDay])

  useEffect(() => {
    if (props.selectedDay) {
      updateYear(props.selectedDay.getFullYear());
      updateMonth(props.selectedDay.getMonth());
    }
  }, [props.selectedDay])

  function previousMonth(isDisabled) {
    if (!isDisabled) {
      if (month === 0) {
        updateYear(year - 1);
        updateMonth(11);
      } else {
        updateMonth(month - 1);
      }
    }
  }

  function prevMonthKeyDown(e, disabled) {
    if(e.which === 13 && !disabled){
      previousMonth(disabled);
    }
  }

  function nextMonth(isDisabled) {
    if (!isDisabled) {
      if (month === 11) {
        updateYear(year + 1);
        updateMonth(0);
      } else {
        updateMonth(month + 1);
      }
    }
  }

  function nextMonthKeyDown(e, disabled) {
    if(e.which === 13 && !disabled){
      nextMonth(disabled);
    }
  }

  function dateConfirmed(date) {
    props.onConfirm(date);
    props.toggle();
    if(props.buttonText === 'confirm start date'){
      analyticsService.analyticsProcessEvent({
        "event": "start_date_modal_click",
        "context": {
          "event_action": "confirm start date",
          "event_detail": {
            "modal": "start date modal"
          }
        }
      })
    }
  }

  useEffect(() => {
    if(props?.reference?.current){
      props.reference.current.focus();
    }
  }, [])

  return (
    <div ref={props.reference} className="join-commute-calendar" role='dialog'>
      <div className="calendar-header" role='group'>
        <button className={'arrow-box ' + (backDisabled ? "disabled" : "enabled")} aria-label='go to previous month' onKeyDown={(e) => prevMonthKeyDown(e, backDisabled)} onClick={() => previousMonth(backDisabled)} role='button' aria-disabled={backDisabled}>
          <i className={"arrow left " + (backDisabled ? "disabled" : "enabled")}></i>
        </button>
        <div aria-label={`${FULL_MONTHS[month]} ${year}`} className='month-year' role='group'>{MONTHS[month]} {year}</div>
        <button className={'arrow-box ' + (forwardDisabled ? "disabled" : "enabled")} aria-label='go to next month' onKeyDown={(e) => nextMonthKeyDown(e, forwardDisabled)} onClick={() => nextMonth(forwardDisabled)} role='button' aria-disabled={forwardDisabled}>
          <i className={"arrow right " + (forwardDisabled ? "disabled" : "enabled")}></i>
        </button>
      </div>
      <div className="calendar-body">
        <div className="table-header" role='group'>
          {
            WEEKDAYS.map((weekday) => {
              return <div key={weekday} className="weekday" aria-label={weekday} role='group'>{weekday.substring(0, 3)}</div>
            })
          }
        </div>
        <CalendarDays
          month={month}
          year={year}
          selectedDay={props.selectedDay}
          enabledDays={props.enabledDays}
          startDay={props.startDay}
          endDay={props.endDay}
          onConfirm={dateConfirmed}
          buttonText={props.buttonText}
          reset={props.reset} />
      </div>
    </div>
  )


}

