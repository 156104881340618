import React, { Component } from 'react';

import currency from '../../shared/currencyFormatter';
import PaymentType from './components/PaymentType';
import PaymentName from './components/PaymentName';
import PaymentAmount from './components/PaymentAmount';
import ExistingCardPaymentMethods from './components/ExistingCardPaymentMethods';
import NewCard from './components/NewCard';
import PaymentSchedule from './components/PaymentSchedule';
import config from '../../appConfig';
import Tick from '../../_styles/images/tick.svg';
import Callable from '../../shared/Callable';
import analyticsService from '../../shared/analytics/analytics-service';

import { NEW_CARD_ID } from '../../shared/credit-card/creditCardService';

export default class RecurringPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      cvc: '',
      name: '',
      selectedCard: {},
      hasFocused: false,
      checked: false
    };
  }

  componentDidMount() {
    if (this.props.payment) {
      this.setState(this.props.payment);
    }

    analyticsService.getPageName({
      'pagename': 'payment/make a payment/select card',
      'process': 'make a payment'
    });
  }

  componentWillReceiveProps(props) {
    if (props.payment) {
      this.setState(props.payment);
    }
  }

  updateState(props) {
    this.setState(props);
  }

  setNameInput(ref) {
    if (!this.state.hasFocused && ref) {
      this.setState({ hasFocused: true });
      ref.focus();
    }
  }

  toggleCheckBox() {
    this.setState({checked: !this.state.checked})
  }

  render() {
    const { review, paymentMethods } = this.props;
    const selected = this.state.selectedCard.id;
    const checked = this.state.checked;
    let payAmount = currency.dollarPad(this.state.amount);
    const existingCardValid = !!this.state.name;
    const canPay =
      payAmount &&
      selected &&
      (selected === NEW_CARD_ID
        ? this.state.selectedCard.valid
        : existingCardValid) &&
        checked;

    return (
      <div className="recurring">
        <div className="title-toggle section">
          <PaymentType recurring={true} />
        </div>

        <PaymentName
          name={this.state.name}
          updateState={state => this.updateState(state)}
          inputRef={ref => this.setNameInput(ref)}
        />

        <PaymentAmount
          amount={this.state.amount}
          updateState={state => this.updateState(state)}
        />

        <ExistingCardPaymentMethods
          paymentMethods={paymentMethods}
          selectedCard={this.state.selectedCard}
          updateState={state => this.updateState(state)}
          cvc={this.state.cvc}
          recurring={true}
        />

        <NewCard
          recurring={this.props.recurring}
          selected={selected}
          updateState={state => this.updateState(state)}
        />

        <PaymentSchedule selectedCard={this.state.selectedCard} />

        <div className='condition' role='group'>
            {checked ?
              <div className='checkbox selected' aria-labelledby="condition" aria-checked='true' onClick={() => this.toggleCheckBox()}  role='checkbox' tabIndex={0}>
                <img src={Tick} aria-hidden='true' />
              </div>
              :
              <div className='checkbox' aria-checked='false' aria-labelledby="condition" onClick={() => this.toggleCheckBox()} role='checkbox' tabIndex={0}></div>
            }
            <div className='text' id="condition" aria-hidden='true'>
            I agree to be charged the amount above automatically monthly until cancelled. I understand I may cancel recurring payments on the app, website or by telephone at 
            {' '}<Callable phone="800 VAN 4 WORK" /> Please allow 24-48 hours for cancellations to take effect.
            </div>
          </div>

        <div className='terms-policy'>
            Your information will be submitted over a secure connection. For additional information, please review our 
            {' '}
            <a
              href={config.privacyPolicyLink}
              target="_blank"
              rel="noopener noreferrer"
              className='link'
            >
               Privacy Policy
            </a>
            {' '}and{' '}
            <a
              href={config.termsOfUseLink}
              target="_blank"
              rel="noopener noreferrer"
              className='link'
            >
              Terms of Use{' '}
            </a>
        </div>

        <div className="tools section">
          <button className="button cancel" onClick={this.props.cancel}>
            Cancel
          </button>
          <button
            className="button primary pay"
            onClick={() => {
                            review(this.state);
                            analyticsService.analyticsProcessEvent({
                              "event": "slide_to_pay",
                              "context": {
                                "event_action": "slide to pay"
                              }
                          });
                      }
                    }
            disabled={!canPay}
          >
            Schedule{payAmount ? ` $${payAmount}` : ' $0.00'}
          </button>
        </div>
      </div>
    );
  }
}
