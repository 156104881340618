import React from 'react';

export const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

function addDay(current, add) {
  return DAYS.filter(day => current.includes(day) || day === add);
}

function removeDay(current, remove) {
  return DAYS.filter(day => current.includes(day) && day !== remove);
}

function disabledMessage(active, disabled) {
  if (active && disabled) {
    return 'Day with riders. Remove riders and try again.';
  } else if (!active && disabled) {
    return 'Unavailable';
  } else if (active && !disabled) {
    return 'Commuting';
  } else {
    return 'Not Commuting';
  }
}

export default function DaysInput({
  value,
  notAllowed = [],
  allowed = DAYS,
  onChange
}) {
  return (
    <fieldset className="days">
      <legend className="visually-hidden">Select days for commuting, please select a minimum of two days</legend>
      {DAYS.map(day => ({
        day,
        disabled: notAllowed.includes(day) || !allowed.includes(day),
        active: value.includes(day)
      })).map(({ day, active, disabled }) => (
        <button
          role='button'
          aria-pressed={active}
          disabled={disabled}
          day={day}
          key={day}
          aria-label={`Select ${day}`}
          onClick={() =>
            onChange(active ? removeDay(value, day) : addDay(value, day))
          }
          type="button"
          title={disabledMessage(active, disabled)}
          className={`day-btn ${active ? 'active' : ''}`}
          tabIndex={disabled ? -1 : 0}
        >
          {day.substr(0, 3)}
        </button>
      ))}
    </fieldset>
  );
}
