import React from 'react';

export default function SliderLevel(props) {
  const { notification, checked, onChange, disabled, selected } = props;
  return (
    <div className="slider-container" role='group' aria-labelledby={notification?.label ? `${notification.label}-label` : undefined}>
      {notification &&
       <div className='left'>
          <div className="text" id={`${notification.label}-label`}>{notification.label}</div>
          <div className="toggle">
            <div className="selected">{selected ? "Yes" : "No"}</div>
            <label className="switch">
              <input
                type="checkbox"
                className="tog-btn"
                checked={!!checked}
                onChange={onChange}
                disabled={disabled}
                aria-checked={checked}
                aria-labelledby={notification?.label ? `${notification.label}-label` : undefined}
                aria-live="polite"
                aria-label={notification.label || 'Toggle'}
              />
              <div className="slider round">
                <span className="on" />
                <span className="off" />
              </div>
            </label>
        </div>
      </div>}

      {notification.text && <div className="label">{notification.text}</div>}
    </div>
  );
}
