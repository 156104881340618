import { useEffect } from 'react';
import config from '../../appConfig';
import analyticsService from '../../shared/analytics/analytics-service';

function NoVansComponent(props) {

  useEffect(() => {
    analyticsService.getPageName({
      "pagename": "matching vans/unable to find matching commute",
      "process": "matching vans"
    })
  }, []);

  return (
    <div className='no-vans-container'>
      <h2 className='heading'>We were unable to find a matching commute</h2>
      <div className='comment'>With new routes being added daily, we'll let you know when your commute becomes available</div>
      <div className='stay-in-touch'>
        <button onClick={props.goToStayInTouch}>STAY IN TOUCH</button>
      </div>
      <div className='comment-two'>You can try changing your route information to see if different commutes are available</div>
      <button className='new-route' onClick={props.goToJoinCommute}>
        FIND A NEW ROUTE
      </button>
      <div className='foot-privacy'>
        View our{' '}
        <a
          href={config.privacyPolicyLink}
          target="_blank"
          rel="noopener noreferrer"
          className='privacy'
        >
          Privacy Policy
        </a>
        {' '}and{' '}
        <a
          href={config.termsOfUseLink}
          target="_blank"
          rel="noopener noreferrer"
          className='terms'
        >
          Terms of Use{' '}
        </a>
      </div>
    </div>
  );
}

export default NoVansComponent;