import * as actionTypes from '../../redux/actionTypes';

import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import SliderLevel from '../../shared/SliderLevel';
import Callable from '../../shared/Callable';
import analyticsService from '../../shared/analytics/analytics-service';
import { SLIDER_DISABLE_TIMEOUT } from '../../shared/helpers/constants';


export function NotificatioPreferences({ preferences, toggle, isSubscription, isPreferenceChanging }) {
  const [disableSlider, updateDisableSlider] = useState(false);

  const notifications = useMemo(() => ({
    'RME': { label: 'Marketing Communication', text: 'I want to receive marketing communications such as newsletters and promotions' },
    'ACC': { label: 'New Vanpool Alerts', text: 'Alert me when new routes are available near me' },
    'NPN': { label: 'Receive Vanpool Participant Payment Emails', text: '' },
    'EPI': { label: 'Paperless Billing', text: '' }
  }), []);

  const filteredPref = !isSubscription ? preferences :
    Object.values(preferences).filter(el => el.preferenceCode !== 'EPI' && el.preferenceCode !== 'NPN');

  function toggled(pref) {
    updateDisableSlider(true);
    const notificationSelected = notifications[pref.preferenceCode];
    analyticsService.analyticsProcessEvent({
      "event": "notification_preference_click",
      "context": {
        "event_action": `notification preferences:${notificationSelected.label && notificationSelected.label.toLowerCase()}:${pref.isSelected ? 'no' : 'yes'}`
      }
    });
    toggle(pref);
  }

  function onClickLink() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": '800 van-4-work'
      }
    });
  }

  useEffect(() => {
    if (!isPreferenceChanging) {
      setTimeout(() => {
        updateDisableSlider(false);
      }, SLIDER_DISABLE_TIMEOUT)
    }
  }, [isPreferenceChanging])

  return (
    <div className="notfication-preferences">
      <div className="header">Email Notification Preferences</div>
      {filteredPref && filteredPref.length > 0 && filteredPref.map(pref => {
        return (
          <div className="preference" key={pref.preferenceCode}>
            <SliderLevel
              notification={notifications[pref.preferenceCode]}
              checked={pref.isSelected}
              disabled={disableSlider}
              onChange={() => toggled(pref)}
              selected={pref.isSelected}
              aria-label={`Toggle ${notifications[pref.preferenceCode].label}`}
            />
          </div>
        );
      })}
      {
        isSubscription && <div className="paperless-billing">
          <div className="content">
            <div className="heading">
              Paperless Billing
            </div>
            <div className="tag">
              Call for changes
            </div>
          </div>
          <div className="text" role="region" aria-label="Billing Preference Information">
            The default billing preference is set to paperless. This preference can be managed only by Commute with Enterprise. To receive billing by mail, or revert back to paperless if you currently receive billing by mail, please call <Callable onClick={onClickLink} className="greentext" phone="800 VAN-4-WORK" aria-label="Call 800 VAN-4-WORK"/><span aria-hidden={true}>.</span>
          </div>

        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isSubscription: state.profile.isSubscription,
    isPreferenceChanging: state.profile.isPreferenceChanging,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggle(pref) {
      dispatch({ type: actionTypes.TOGGLE_PREFERENCE, data: pref });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificatioPreferences);