import Dropdown from '../../../join-commute/payment-screen/dropdown/Dropdown';

export default function PhoneSection(props) {
  return (
    <div className='number'>
      <div className='field'>
        <Dropdown
          label="Phone Type"
          onChange={(value) => props.onChange('type', value)}
          value={props.number.type}
          options={props.options}
          name={props.name}/>
      </div>
      <fieldset className='field number-field'>
        <div className='label'>
          <label htmlFor={props.numberLabel}>{props.numberLabel}</label>
        </div>
        <input id={props.numberLabel} aria-invalid={!props.valid} aria-describedby="phoneError" type='text' className={props.valid ? 'number-field' : 'number-field error'} onChange={(e) => props.onChange('number', e.target.value)} value={props.number.number || ''} placeholder='Phone Number'></input>
        {props.valid ? null
          :
          <div className="invalid-text" aria-live='assertive' id="phoneError">
            {'Please enter a valid phone number'}
          </div>
        }
      </fieldset>
      {
        props.number.type === 'Work' &&
        <fieldset className='field extension'>
          <div className='label' >
            <label htmlFor='extension'>Ext.</label>
          </div>
          <input id='extension' aria-label='Extension Number' onChange={(e) => props.onChange('extension', e.target.value)} value={props.number.extension || ''} placeholder='12345'></input>
        </fieldset>
      }
    </div>
  );
}