import React from 'react';
import { Link } from 'react-router-dom';
import dateUtility from '../../../shared/dateUtility';
import creditCardService from '../../../shared/credit-card/creditCardService';
import { Warning } from '../../../shared/icons';
import CVCThreeDigits from '../../../_styles/images/cvc-right.svg';
import CVCFourDigits from '../../../_styles/images/cvc-amex.svg';
import Radio from './Radio';
import analyticsService from '../../../shared/analytics/analytics-service';

export default function(props) {
  return (
    <div className="payment-methods section">
      <h3>
        Choose Payment Method{' '}
        <Link to="/profile/payment-info">Update Payment Methods</Link>{' '}
      </h3>
      <div className="cards">
        {props.paymentMethods.map((item, itemIndex) => (
          <Radio
            onSelect={() => selectCard(item, props)}
            key={itemIndex}
            disabled={dateUtility.beforeToday(item.exp)}
            selected={props.selectedCard.id === item.id}
            className="card"
          >
            <div className="logo">
              <img
                className="card-image"
                src={creditCardService.getImage(item.brand)}
                alt="Credit Card"
              />
            </div>
            <div className="details">
              <div className="account">***{item.account}</div>
              <div
                className={`exp ${
                  dateUtility.beforeToday(item.exp) ? 'expired' : ''
                }`}
              >
                Exp {item.exp}
                {dateUtility.beforeToday(item.exp) ? <Warning /> : null}
              </div>
            </div>
            {!props.recurring ? (
              <div className="cvc">
                <input
                  id={'cvc-' + item.id}
                  value={props.cvc}
                  onChange={e => updateCVC(e.target.value, props)}
                  placeholder="CVC"
                  autoFocus
                />
                <div className="popout">
                  <div className="cvc-prompt">Please enter CVC</div>
                  <img
                    src={
                      props.selectedCard.cvcLength === 4
                        ? CVCFourDigits
                        : CVCThreeDigits
                    }
                    alt="CVC help"
                  />
                </div>
              </div>
            ) : null}
          </Radio>
        ))}
        {props.paymentMethods.length % 3 > 0 ? (
          <div className="card spacer" />
        ) : null}
        {props.paymentMethods.length % 3 === 1 ? (
          <div className="card spacer" />
        ) : null}
      </div>
    </div>
  );
}

////////

export function selectCard(card, props) {
  if (card.id === props.selectedCard.id) {
    return;
  }

  if (props.recurring) {
    return props.updateState({ selectedCard: card, cvc: '000' });
  }

  card.cvcLength = creditCardService.cardTypeForBrand(card.brand).cvcLength;

  props.updateState({ selectedCard: card, cvc: '' });

  analyticsService.analyticsProcessEvent({
    "event": "make_payment_select_card",
    "context": {
      "event_action": "select card"
    }
  });
}

export function updateCVC(value, props) {
  if (new RegExp(`^[0-9]{0,${props.selectedCard.cvcLength}}$`).test(value)) {
    props.updateState({ cvc: value });
  }
}
