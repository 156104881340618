import { useEffect } from 'react';
import Success from '../../../_styles/images/Success.svg';
import analyticsService from '../../../shared/analytics/analytics-service';

export default function SuccessPopup(props) {

  useEffect(() => {
    analyticsService.analyticsProcessEvent(
      {
        "event": "personal_information_updated_modal_view",
        "context": {
          "event_action": "personal information updated modal"  
          }
      });
  }, [])
  return (
    <div className='card-operations-container' tabIndex={0} ref={props.reference} aria-modal="true" role="dialog" aria-labelledby="success-heading" aria-describedby="success-text">
      <div className='delete-confirmation-container'>
        <div className='caution-icon'>
          <img src={Success} aria-hidden={true}></img>
        </div>
        <div id="success-heading" className='heading' tabIndex={0}>{props.heading}</div>
        <div id="success-text" className='foot-notes' tabIndex={0}>{props.text}</div>
        <div className='buttons'>
          <button className='confirmation-delete-button' disabled={false} tabIndex={0} onClick={props.confirm} aria-label={props.confirmButtonText}>{props.confirmButtonText}</button>
        </div>
      </div>
    </div>
  );
}
